import { useState, useEffect, useRef } from 'react'
import { services } from './services'

import Slider from "react-slick";
import { useInView } from 'react-intersection-observer';
import { isMobile } from 'react-device-detect';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import team_1 from './img/team/team_1.webp' 
import team_2 from './img/team/team_2.webp' 
import team_3 from './img/team/team_3.webp' 
import team_4 from './img/team/team_4.webp' 
import team_5 from './img/team/team_5.webp' 
import team_6 from './img/team/team_6.webp' 
import team_7 from './img/team/team_7.webp' 
import team_8 from './img/team/team_8.webp' 
import team_9 from './img/team/team_9.webp' 
import team_10 from './img/team/team_10.webp' 
import team_11 from './img/team/team_11.webp' 
import team_12 from './img/team/team_12.webp' 
import team_13 from './img/team/team_13.webp' 
import team_14 from './img/team/team_14.webp' 
import team_15 from './img/team/team_15.webp' 
import team_16 from './img/team/team_16.webp' 

function Team( { service } ) {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if( !isMobile ) {
      if(inView) {
        document.getElementById("ig-cont").classList.add("fl-black");
        document.getElementById("ig-icon").classList.add("float-icon-black");
        document.getElementById("fb-cont").classList.add("fl-black");
        document.getElementById("fb-icon").classList.add("float-icon-black");
        document.getElementById("tk-cont").classList.add("fl-black");
        document.getElementById("tk-icon").classList.add("float-icon-black");
        document.getElementById("yt-cont").classList.add("fl-black");
        document.getElementById("yt-icon").classList.add("float-icon-black");
        document.getElementById("in-cont").classList.add("fl-black");
        document.getElementById("in-icon").classList.add("float-icon-black");
      } else {
        document.getElementById("ig-cont").classList.remove("fl-black");
        document.getElementById("ig-icon").classList.remove("float-icon-black");
        document.getElementById("fb-cont").classList.remove("fl-black");
        document.getElementById("fb-icon").classList.remove("float-icon-black");
        document.getElementById("tk-cont").classList.remove("fl-black");
        document.getElementById("tk-icon").classList.remove("float-icon-black");
        document.getElementById("yt-cont").classList.remove("fl-black");
        document.getElementById("yt-icon").classList.remove("float-icon-black");
        document.getElementById("in-cont").classList.remove("fl-black");
        document.getElementById("in-icon").classList.remove("float-icon-black");
      }
    }
  }, [inView])
  
  return (
    <div>

      <div className='section-D'>
        <Container>
          <Row>
            <div className='section-title'>NOSOTROS</div>
          </Row>

            <br/>
            { !isMobile && <br/> }
            <Row className="align-items-center">
              <Col md={ 4 }>
                <div className='stroke-text'>HOLA,</div>
              </Col>
              <Col md={ 8 }>
                <div className='desc-text'>
                  <div>Expertos en consultoría, análisis y desarrollo de planes estratégicos de publicidad y medios.</div>
                  <div>Nuestro esfuerzo, dedicación y compromiso lo enfocamos en el éxito de tu marca.</div>
                </div>
              </Col>
          </Row>

        </Container>
      </div>

      <div className='section-E'>
        <Container>
          <Row>
            <Col md={ 2 }></Col>
            <Col md={ 8 }>
              <div className='team-title-stoke'>CREATIVOS</div>
              <div className='team-title' style={{ textTransform: 'uppercase' }}>Multidisciplinarios</div>
            </Col>
            <Col md={ 2 }></Col>
          </Row>
          <br/><br/><br/>
          <Row>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_1 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_2 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_3 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_4 } className='w-100' />
            </Col>
          </Row>
          <Row ref = { ref }>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_5 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_6 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_7 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_8 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_9 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_10 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_11 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_12 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_13 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_14 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_15 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_16 } className='w-100' />
            </Col>
            {/* !isMobile && <Col md= { 3 } xs={ 6 } className='p-4-custom'> 
            </Col> */}
            {/* <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_17 } className='w-100' />
            </Col>
            <Col md= { 3 } xs={ 6 } className='p-4-custom'>
              <img alt="" width="auto" height="auto" src={ team_18 } className='w-100' />
            </Col>*/} 
            {/* !isMobile && <Col md= { 3 } xs={ 6 } className='p-4-custom'> 
            </Col> */}
          </Row>
        </Container>
      </div>

    </div>
  );
}

export default Team;
