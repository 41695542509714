import { useState, useEffect, useRef } from 'react'
import { services, carousel } from './services'

import Slider from "react-slick";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import { isMobile } from 'react-device-detect';

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import arrow from './img/arrow.webp';

const serviceLink = [
  {
    path: '/digital',
    index: 0
  },
]

var settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: isMobile ? 2 : 4,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  dots: false,
  arrows: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  pauseOnDotsHover: false
};

function ServicesComp( { service } ) {
  const [ serv, setServ ] = useState( { frontImg: null, title: "", body: null , carousel: [], bullets: [] } )
  
  useEffect(() => {
    setServ(services[0])
    var found = services.filter(function(item) { return item.link === service; });
    if(found) setServ(found[0])

    if( !isMobile ) {
      document.getElementById("ig-cont").classList.add("fl-black");
      document.getElementById("ig-icon").classList.add("float-icon-black");
      document.getElementById("fb-cont").classList.add("fl-black");
      document.getElementById("fb-icon").classList.add("float-icon-black");
      document.getElementById("tk-cont").classList.add("fl-black");
      document.getElementById("tk-icon").classList.add("float-icon-black");
      document.getElementById("yt-cont").classList.add("fl-black");
      document.getElementById("yt-icon").classList.add("float-icon-black");
      document.getElementById("in-cont").classList.add("fl-black");
      document.getElementById("in-icon").classList.add("float-icon-black");
    }
  }, []);

  return (
    <div>

      <Container>
        <Row>
          
          <div className='services-home'>
            <img alt="" width="auto" height="auto" src={ serv.frontImg } className='services-home-img'/>
            <div className='services-title'>
              <div style={ !isMobile ? { paddingBottom: '25px' } : {} }>{ serv.title }</div>
              { !isMobile && <div className='services-desc'>{ serv.desc }</div> }
            </div>
          </div>
          <div className='services-body' align='center'>
            <Container className='limited-conteiner'>
              { serv.bullets.map( (item, i) => {
                if (i % 2 == 0) {
                  return <>
                    <Row className="align-items-center service-row">
                      <div className="service-back-a"> 
                        { isMobile && <div className="service-back-child" style={{ backgroundColor: item.color }}></div> }
                        
                        { !isMobile && 
                          <div className='service-back-image' style={{ backgroundImage: 'url("' + item.background + '")' }}></div>
                        }
                          {/*<div class="freebirdMaterialHeaderbannerSectionTriangleContainer">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 10" preserveAspectRatio="none" class="freebirdMaterialHeaderbannerSectionTriangle"> 
                                <polygon class="freebirdSolidFill" style={{ fill: item.color, stroke: item.color }} points="0,0 10,0 0,10"></polygon>
                            </svg>

                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.9 9.6" preserveAspectRatio="xMidYMid meet" className="freebirdMaterialHeaderbannerSectionTriangle">
                                <path d="M0.7,0h14.5c0.4,0,0.7,0.3,0.7,0.7v8.2c0,0.5-0.5,0.8-1,0.6L0.4,3.3C0.2,3.2,0,2.9,0,2.6V0.7C0,0.3,0.3,0,0.7,0z" style={{ fill: item.color, stroke: item.color }}/>
                            </svg> 

                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14.2 9.6" className="freebirdMaterialHeaderbannerSectionTriangle">
                              <g>                              
                                <g>
                                  <path className="st0" d="M1.2,0.1c0.3,0,0.5,0,0.8,0c1.8,0.1,3.6,0.1,5.4,0.1c1.7,0,3.5-0.2,5.2-0.1c0.7,0,1.5,0.3,1.6,1.1
                                    c0,0.1,0,0.2,0,0.3c0,0,0,6.9,0,6.9c0,0.8-0.4,1.5-1.2,1.2c-1.6-0.5-3.2-1.2-4.8-1.7C6.3,7.2,4.5,6.6,2.8,6
                                    C2.2,5.8,1.5,5.6,0.9,5.4c-0.8-0.3-1-1.1-1-1.9c0,0,0-2.4,0-2.4C-0.1,0.4,0.5,0.1,1.2,0.1z" style={{ fill: item.color, stroke: item.color }}/>
                                </g>
                              </g>
                            </svg>

                          </div> */}
                        
                      </div>
                      <Col md={ 6 }>
                        <Player autoplay loop src={ item.animation } className='animation-img'>
                          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                      </Col>
                      <Col md={ 6 }>
                        <div className='body-container-right'>
                          <div className='service-title' style={ isMobile ? { textAlign: 'center' } : { textAlign: 'left' } }> { item.title } </div>
                          <div className='service-desc' style={{ textAlign: 'left' }}> { item.body } </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                } else {
                  return <>
                      <Row className="align-items-center service-row">
                      <div className="service-back-a">
                        { isMobile && <div className="service-back-child" style={{ backgroundColor: item.color }}></div> }
                        
                        { !isMobile && 
                          <div className='service-back-image-b' style={{ backgroundImage: 'url("' + item.background + '")' }}></div>
                        }
                        {/*<div class="freebirdMaterialHeaderbannerSectionTriangleContainer">
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 10" preserveAspectRatio="none" class="freebirdMaterialHeaderbannerSectionTriangle"> 
                                <polygon class="freebirdSolidFill" style={{ fill: item.color, stroke: item.color }} points="0,0 10,0 0,10"></polygon>
                            </svg>

                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 10 10"  preserveAspectRatio="none" className="freebirdMaterialHeaderbannerSectionTriangle">
                              <path d="M0,0h10C8.2,3.3,6.5,6.7,4.7,10c-1.6,0-3.1,0-4.7,0" style={{ fill: item.color, stroke: item.color }}/>
                            </svg>

                            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"viewBox="0 0 15.9 9.6" preserveAspectRatio="xMidYMid meet" className="freebirdMaterialHeaderbannerSectionTriangle">
                              <path d="M15.2,9.6H0.7C0.3,9.6,0,9.3,0,8.9V0.7c0-0.5,0.5-0.8,1-0.6l14.5,6.3c0.3,0.1,0.4,0.4,0.4,0.6v1.9
                              C15.9,9.3,15.5,9.6,15.2,9.6z" style={{ fill: item.color, stroke: item.color }}/>
                            </svg>

                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.7 9.6" className="freebirdMaterialHeaderbannerSectionTriangle">
                              <g>
                                <g>
                                  <path class="st0" d="M0,1.2c0,0.1,0,0.1,0,0.2C0,2.9,0,4.5,0,6C0,7.2-0.3,8.9,1,9.4c0.5,0.2,1,0.2,1.5,0.2c3.3,0,6.6,0,9.9,0
                                    c0.4,0,0.7,0,1-0.3c0.4-0.3,0.4-0.9,0.4-1.5c0-0.7,0-1.5,0-2.2c0-0.3,0-0.5-0.1-0.8c-0.2-0.4-0.5-0.5-0.9-0.7
                                    C9.6,3,6.5,1.8,3.4,0.7C2.7,0.5,2.1,0.3,1.5,0.1C1.1,0,0.7-0.1,0.4,0.2C0.1,0.4,0.1,0.8,0,1.2z" style={{ fill: item.color, stroke: item.color }}/>
                                </g>
                              </g>
                            </svg> 
                          </div> */}
                      </div>
                      <Col md={ 6 }>
                        <div className="body-container-left">
                          <div className='service-title' style={ isMobile ? { textAlign: 'center' } : { textAlign: 'left' } }> { item.title } </div>
                          <div className='service-desc' style={{ textAlign: 'left' }}> { item.body } </div>
                        </div>
                      </Col>
                      <Col md={ 6 }>
                        <Player autoplay loop src={ item.animation } className='animation-img'>
                          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                        </Player>
                      </Col>
                    </Row>
                  </>
                }
              })}
              
            </Container>
            {/*<img alt="" width="auto" height="auto" src={ serv.body } className='img-80'/>*/}
          </div>
          
          <div className='slider-style'>
            <br/>
            <br/>
            <Slider {...settings}>
              { carousel.map( item => (
                <div>
                  <img alt="" width="auto" height="auto" src={ item } className='service-carousel-img' />
                </div>
              )) }
            </Slider>
          </div>

          <div align='center' className='section-grl'>
            <Container>
              <Row className='service-cont'>
                <Col md={ 1 } className="services-space"></Col>
                <Col md={ 2 } className={ service == '/digital' ? 'hidden' : 'no-padding services-items' }><a aria-label=""href={ '/digital' }><div className="service-button acc-salmon"> Digital <br/>media &nbsp; <img alt="" width="auto" height="auto" src={ arrow } className='arrow-small'/> </div> </a></Col>
                <Col md={ 2 } className={ service == '/bellow' ? 'hidden' : 'no-padding services-items' }><a aria-label=""href={ '/bellow' }><div className="service-button acc-green"> Bellow <br/>The Line &nbsp; <img alt="" width="auto" height="auto" src={ arrow } className='arrow-small'/> </div> <div className={ service == '/digital' ? 'hidden' : "background-comp acc-green" }></div> </a></Col>
                <Col md={ 2 } className={ service == '/posicionamiento' ? 'hidden' : 'no-padding services-items' }><a aria-label=""href={ '/posicionamiento' }><div className="service-button acc-blue"> Medios de <br/>posicionamiento &nbsp; <img alt="" width="auto" height="auto" src={ arrow } className='arrow-small'/> </div> <div className="background-comp acc-blue"></div> </a></Col>
                <Col md={ 2 } className={ service == '/outofhome' ? 'hidden' : 'no-padding services-items' }><a aria-label=""href={ '/outofhome' }><div className="service-button acc-salmon"> Out of <br/>Home &nbsp; <img alt="" width="auto" height="auto" src={ arrow } className='arrow-small'/> </div> <div className="background-comp acc-salmon"></div> </a></Col>
                <Col md={ 2 } className={ service == '/especiales' ? 'hidden' : 'no-padding services-items' }><a aria-label=""href={ '/especiales' }><div className="service-button acc-green"> Servicios <br/>Especiales &nbsp; <img alt="" width="auto" height="auto" src={ arrow } className='arrow-small'/> </div> <div className="background-comp acc-green"></div> </a></Col>
                <Col md={ 2 } className={ service == '/eventos' ? 'hidden' : 'no-padding services-items' }><a aria-label=""href={ '/eventos' }><div className="service-button acc-blue"> Eventos y <br/>exposiciones &nbsp; <img alt="" width="auto" height="auto" src={ arrow } className='arrow-small'/> </div> <div className="background-comp acc-blue"></div> </a></Col>
                <Col md={ 1 } className="services-space"></Col>
              </Row>
            </Container>
          </div>

        </Row>
      </Container>

    </div>
  );
}

export default ServicesComp;
