import { useState, useEffect, useRef } from 'react'
import { services } from './services'

import { Player, Controls } from '@lottiefiles/react-lottie-player';
import ReactPlayer from 'react-player'
import Marquee from "react-fast-marquee";
import { useHover } from "@uidotdev/usehooks";
import { useInView } from 'react-intersection-observer';
import { isMobile } from 'react-device-detect';


import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Accordion from 'react-bootstrap/Accordion'

import nosotros_btn from './img/nosotros_btn.webp';
import us from './img/us.webp';
import arrow from './img/arrow.webp';

import cl1 from './img/clientes/agave.webp'
import cl2 from './img/clientes/alsea.webp'
import cl3 from './img/clientes/anfora.webp'
import cl4 from './img/clientes/anforama.webp'
import cl5 from './img/clientes/anne.webp'
import cl6 from './img/clientes/bed.webp'
import cl7 from './img/clientes/cet.webp'
import cl8 from './img/clientes/clemont.webp'
import cl9 from './img/clientes/cristal.webp'
import cl10 from './img/clientes/cudec.webp'
import cl11 from './img/clientes/devlin.webp'
import cl12 from './img/clientes/dnatural.webp'
import cl13 from './img/clientes/dominos.webp'
import cl14 from './img/clientes/esperanza.webp'
import cl15 from './img/clientes/gfa.webp'
import cl16 from './img/clientes/h.webp'
import cl17 from './img/clientes/helvex.webp'
import cl18 from './img/clientes/kfc.webp'
import cl19 from './img/clientes/liverpool.webp'
import cl20 from './img/clientes/mega.webp'
import cl21 from './img/clientes/nelson.webp'
import cl22 from './img/clientes/pg.webp'
import cl23 from './img/clientes/pilgrim.webp'
import cl24 from './img/clientes/pizza.webp'
import cl25 from './img/clientes/plaza.webp'
import cl26 from './img/clientes/qualitas.webp'
import cl27 from './img/clientes/quest.webp'
import cl28 from './img/clientes/sph.webp'
import cl29 from './img/clientes/supra.webp'
import cl30 from './img/clientes/suzuki.webp'
import cl31 from './img/clientes/tere.webp'
import cl32 from './img/clientes/toks.webp'
import cl33 from './img/clientes/xbox.webp'
import cl34 from './img/clientes/yza.webp'
import cl35 from './img/clientes/zarza.webp'
import cl36 from './img/clientes/casa.webp'

import Us from './img/animations/nosotros.json'

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function Home( { refHome, refTeam, refServices, refClients } ) {
  const { ref, inView, entry } = useInView({
    threshold: 0,
  });

  const [ actKey, setActKey ] = useState(-1);
  const [ref0, hovering0] = useHover();
  const [ref1, hovering1] = useHover();
  const [ref2, hovering2] = useHover();
  const [ref3, hovering3] = useHover();
  const [ref4, hovering4] = useHover();
  const [ref5, hovering5] = useHover();

  const marquee_1 = [ cl1,cl2,cl3,cl4,cl5,cl6,cl7,cl8,cl9,cl10,cl11,cl12,cl13,cl14,cl15,cl16,cl17,cl18 ]
  const marquee_2 = [ cl19,cl20,cl21,cl22,cl23,cl24,cl25,cl26,cl27,cl28,cl29,cl30,cl31,cl32,cl33,cl34,cl35,cl36 ]

  useEffect(() => {
    
    /*if( hovering0 ) setActKey(0)
    else if( hovering1 ) setActKey(1)
    else if( hovering2 ) setActKey(2)
    else if( hovering3 ) setActKey(3)
    else if( hovering4 ) setActKey(4)
    else if( hovering5 ) setActKey(5)
    else setActKey(-1)*/

  }, [ hovering0, hovering1, hovering2, hovering3, hovering4, hovering5 ]);

  useEffect(() => {
    if( !isMobile ) {
      if(inView) {
        document.getElementById("ig-cont").classList.add("fl-black");
        document.getElementById("ig-icon").classList.add("float-icon-black");
        document.getElementById("fb-cont").classList.add("fl-black");
        document.getElementById("fb-icon").classList.add("float-icon-black");
        document.getElementById("tk-cont").classList.add("fl-black");
        document.getElementById("tk-icon").classList.add("float-icon-black");
        document.getElementById("yt-cont").classList.add("fl-black");
        document.getElementById("yt-icon").classList.add("float-icon-black");
        document.getElementById("in-cont").classList.add("fl-black");
        document.getElementById("in-icon").classList.add("float-icon-black");
      } else {
        document.getElementById("ig-cont").classList.remove("fl-black");
        document.getElementById("ig-icon").classList.remove("float-icon-black");
        document.getElementById("fb-cont").classList.remove("fl-black");
        document.getElementById("fb-icon").classList.remove("float-icon-black");
        document.getElementById("tk-cont").classList.remove("fl-black");
        document.getElementById("tk-icon").classList.remove("float-icon-black");
        document.getElementById("yt-cont").classList.remove("fl-black");
        document.getElementById("yt-icon").classList.remove("float-icon-black");
        document.getElementById("in-cont").classList.remove("fl-black");
        document.getElementById("in-icon").classList.remove("float-icon-black");
      }
    }
  }, [inView])

  const onEnterBody = (item) => {
    var icon = document.getElementById("arrow_" + item.key);
    icon.classList.add("rotated");

    if( item.final ) {
      var el = document.getElementById('acc_item_' + item.key);
      el.classList.remove("acc-border-bottom");
    }
  }

  const onOutBody = (item) => {
    var icon = document.getElementById("arrow_" + item.key);
    icon.classList.remove("rotated");

    if( item.final ) {
      var el = document.getElementById('acc_item_' + item.key);
      el.classList.add("acc-border-bottom");
    }
  }

  return (
    <div>

      <Container ref={refHome} className="home-section">
          <ReactPlayer className="player-v" url='mgp.mp4' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ true } />  
          {/* <ReactPlayer className="player-v" url='https://youtu.be/vCQz7kQDtCE' playing={ true } loop={ true } volume={ 0 } muted={ true } controls={ false }/> */}
      </Container>

      <div ref={refTeam} className='section-A'>
        <Container>
          <Row>
            <div className='section-title'>NOSOTROS</div>
          </Row>

          <br/>
          { !isMobile && <br/> }
          <Row>
            <Col md={ 6 }>
              <div className='stroke-text'>HOLA,</div>

              <div className='desc-text'>
                Somos una agencia con más de 15 años de experiencia
              </div>

              <br/>
              <a aria-label=""href='/team'><div>
                <img alt="" width="auto" height="auto" src={ nosotros_btn } className='img-us' />
              </div></a>
            </Col>

            <Col md={ 6 }>
              <div align="center">
                { isMobile && <br/> }
                {/* <img alt="" width="auto" height="auto" src={ us } className='img-90'/> */}
                <Player autoplay loop src={ Us } className='animation-img'>
                  <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                </Player>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <div ref={refServices} className='section-B'>
        <Container className='service-container'>
          <Row>
            <div className='section-title black-color'>SERVICIOS</div>
          </Row>
        </Container>
      </div>
      <div ref={ ref }>
        <Container className='service-container'>
          <Row>
            <Col md={ 12 }>
              <Accordion activeKey={ actKey }>
                { services.map( item => (
                  <a aria-label=""href={ item.link } >
                    <Accordion.Item ref={ eval( 'ref' + item.key ) } eventKey={ item.key } className={ item.final ? "acc-border acc-border-bottom" : 'acc-border' }>
                      <Accordion.Header className={ item.final ? item.backgroundButton + ' acc-border-bottom' : item.backgroundButton }>
                        <Container id={ 'acc_item_' + item.key } className={ item.final ? 'acc-border p-20 acc-border-bottom ' + item.classColor : 'acc-border p-20 ' + item.classColor} style={ !isMobile && item.final ? { paddingBottom: '20px' } : {} } >
                          <Row className="align-items-center">
                            { isMobile &&
                              <Col>
                                <div className='accordion-title'> { item.title } </div>
                              </Col>
                            }
                            <Col md={ 4 } style={ isMobile ? { textAlign: 'center' } : {}  }>
                              <img alt="" width="auto" height="auto" src={ isMobile && item.final ? item.imgHeaderMobile : item.header_image } className='acc-img'/>
                            </Col>
                            { !isMobile && 
                              <Col>
                                <div className='accordion-title'> { item.title } </div>
                              </Col>
                            }
                            <Col md={ 'auto' } style={ isMobile ? { display: 'none' } : {}  }>
                              <img alt="" width="auto" height="auto" id={ "arrow_" + item.key } src={ arrow } className='acc-arrow'/>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Header>
                      <Accordion.Body className={ item.final ? item.classColor + ' acc-border-bottom' : item.classColor } onEnter={ () => onEnterBody( item ) } onExit={ () => onOutBody( item ) }>
                        <Container>
                          <Row className="align-items-center">
                            <Col md={ 'auto' } style={{ position: 'relative' }}>
                              <img alt="" width="auto" height="auto" src={ item.body_image } className='acc-img'/>
                            </Col>
                            <Col>
                              <div className='acc-desc'> 
                                { item.desc }
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </Accordion.Body>
                    </Accordion.Item>
                  </a>
                ))}
              </Accordion>
            </Col>
          </Row>
        </Container>

        <div ref={refClients} className='section-B' style={ isMobile ? { paddingLeft: 0, paddingRight: 0 } : {}}>
          <Container>
            <Row>
              <div className='section-title black-color'>CLIENTES</div>
              <br/>
              <Marquee autoFill={ true } gradient={ !isMobile } className='marquee-custom'> 
                { marquee_1.map( item => (
                  <div> <img alt="" width="auto" height="auto" src={ item } className='img-marquee' /> </div>
                ))}
              </Marquee>
              <Marquee autoFill={ true } gradient={ !isMobile } className='marquee-custom' direction='right'> 
                { marquee_2.map( item => (
                  <div> <img alt="" width="auto" height="auto" src={ item } className='img-marquee' /> </div>
                ))}
              </Marquee>
            </Row>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default Home;
